import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
import { Link } from 'gatsby';
import Sidebar from 'components/sidebar';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`What do vacuums, gummies, & chalkboards all have in common?`}</h1>
    <p><strong parentName="p">{`Venture 1 of 3:`}</strong>{` Raise a
$1m+ fund in 30 days from people I've never met before.`}<br />{`
`}<strong parentName="p">{`Status:`}</strong>{` Raised $1.7M
in 8 days, within striking range of our $2.4M "f`}{`*`}{`ck yes!" target`}</p>
    <p>{`Today - we decided to divide & conquer.`}</p>
    <p>{`Ben is chasing down all the people who were "interested" but didn't close yet.
We should be able to get to $2M just by doing a round of follow-ups. We’ll come
back to this in our updates in a few days...for now, it’s just rinse and repeat
on follow up.`}</p>
    <p><strong parentName="p">{`Me? I'm switching gears.`}</strong></p>
    <p>{`The all access pass promised launching 3 different $1M+ ventures. One was the
fund, The second is a digital or information product (coming next), and the
third is ecommerce.`}</p>
    <p><strong parentName="p">{`I'm a little worried about ecommerce.`}</strong></p>
    <p>{`Why? I helped my wife start an ecom biz last year - and it took us 6 months to
get to market. Finding a product, finding a supplier, getting samples, going
back and forth with design iterations etc… this shit takes time.`}</p>
    <p>{`So I need to start planting seeds for the ecommerce project now, so that it is
ready to roll in a couple months.`}</p>
    <p>{`So today's mission is to plant the seeds for our ecommerce project.`}</p>
    <p><strong parentName="p">{`The key things are:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Brainstorm a list of potential products (Today's OBT)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Then, once we know the potential products, find a partner to help with
sourcing/getting quotes`}</strong></li>
    </ul>
    <p>{`My goal for the day is to finish with a list of 10-20 products, with at least
2-3 that I'm excited about.`}</p>
    <p>{`OK let's start generating ideas. I'm looking for `}<strong parentName="p">{`quantity`}</strong>{` not `}<strong parentName="p">{`quality`}</strong>{`
when brainstorming. I'm also not looking for "complete ideas" - I really just
want to brainstorm product categories at this point. Later I'll drill down
deeper and think of differentiated products within each category/product I like.`}</p>
    <p><strong parentName="p">{`My first brainstorming tactic: THE HOUSE`}</strong></p>
    <p>{`What do I see around the house? What products did we buy recently? What do I
notice friends using? What’s my mom using?`}</p>
    <p><strong parentName="p">{`House Stuff`}</strong><br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Dyson vacuum`}</strong>{` - the cordless/handheld one. Super slick, love this product`}</li>
      <li parentName="ul"><strong parentName="li">{`Gorilla Tape`}</strong>{` - super strong tape for fixing stuff up around the house`}</li>
      <li parentName="ul"><strong parentName="li">{`Tupperware`}</strong>{` - nice containers for leftovers`}</li>
    </ul>
    <p><strong parentName="p">{`Kids Stuff`}</strong><br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Furniture corner`}</strong>{` protectors for the kid`}</li>
      <li parentName="ul"><strong parentName="li">{`Stuffed animals`}</strong>{` for the baby`}</li>
      <li parentName="ul"><strong parentName="li">{`School supplies`}</strong>{` for young kids now at home`}</li>
    </ul>
    <p><strong parentName="p">{`Tech Gadgets`}</strong><br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Iphone holder`}</strong>{` attachment for tripod for vlogging`}</li>
      <li parentName="ul"><strong parentName="li">{`Crazy USB port`}</strong>{` so I can plug like 10 things into 1 macbook port`}</li>
      <li parentName="ul"><strong parentName="li">{`Camlink`}</strong>{` so I can use a DLSR camera instead of webcam when on zoom`}</li>
      <li parentName="ul"><strong parentName="li">{`Airpod replacement case`}</strong>{` - I have my airpods but lost the case`}</li>
      <li parentName="ul"><strong parentName="li">{`Remarkable Tablet`}</strong>{` - e-ink notepad for writing`}</li>
    </ul>
    <p><strong parentName="p">{`Personal Care:`}</strong><br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Men's headbands`}</strong>{` - growing my hair out during covid`}</li>
      <li parentName="ul"><strong parentName="li">{`Hair oil`}</strong>{` - related to growing my hair out. It's super dry so I bought some
oil`}</li>
    </ul>
    <p><strong parentName="p">{`Fitness:`}</strong><br /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Adjustable Dumbbells`}</strong>{` - sold out everywhere, bought these marked up on
craiglist`}</li>
      <li parentName="ul"><strong parentName="li">{`Jump rope`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Various Resistance bands`}</strong>{` for home workouts`}</li>
    </ul>
    <p><strong parentName="p">{`Nutrition`}</strong><br /></p>
    <ul>
      <li parentName="ul">{`Wife bought `}<strong parentName="li">{`"apple cider vinegar gummies"`}</strong>{` - supposed to be good for you`}</li>
      <li parentName="ul"><strong parentName="li">{`Branch Chain Amino Acids (BCAAs)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Plant based protein powder`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Hot sauce`}</strong>{` - very loosely nutrition, but I put hot sauce on everything,
which makes it way easier to eat healthy`}</li>
    </ul>
    <p>{`OK, running out of steam. Gotta switch it up to keep the ideas flowing.`}</p>
    <p>{`BTW, hopefully you now understand the subject line......`}</p>
    <p><strong parentName="p">{`My Second Brainstorming Tactic: What's TRENDY, that I can also find on
Aliexpress?`}</strong></p>
    <p>{`If you don't know - Alibaba is a giant company based in China that lets you find
factories that make pretty much anything. Crazy tents. Jetpacks. Hair Curlers.
Whatever you can think of - they have it.`}</p>
    <p>{`Aliexpress is their lightweight version. On Alibaba you have to order big
quantities (eg. ordering 1000 hair bows), but on Aliexpress you can buy a single
unit for a little higher price (but still way cheaper than anything in the US).
Only downside is that you have to wait a month for it to arrive and the quality
is a complete hit or miss haha.`}</p>
    <p>{`Anytime I see an ad for a product on instagram, I go search it on Aliexpress.
Theragun? You can either pay
$200 or you can go on aliexpress and find one for $60 if you're willing to wait
3 weeks.`}</p>
    <p>{`Anyways - to get idea inspiration - I have a secret weapon.`}</p>
    <p>{`There's a TikTok account called
`}<a parentName="p" {...{
        "href": "https://www.tiktok.com/@wonderlife_aliexpress"
      }}>{`Wonderlife_Aliexpress`}</a>{` that
showcases the best random products. `}{`*`}{`*`}{`Warning - clicking that will mean that your
entire tiktok feed will be random products...their algorithm is sooo good.`}{`*`}{`*`}</p>
    <p>{`Anyways, I like this way better than browsing aliexpress - and because it's on
tiktok, it's already formatted like an "ad" I would create to sell this product.
It makes it really easy to imagine - would this sell? Would this pop?`}</p>
    <p><strong parentName="p">{`I liked these 3:`}</strong></p>
    <ul>
      <li parentName="ul">{`This
`}<a parentName="li" {...{
          "href": "https://www.tiktok.com/@wonderlife_aliexpress/video/6870777770771893506?language=en&sec_uid=MS4wLjABAAAAx5EuSNfZComEX7bxksQlHZHRpZPwlR02T46JDbvTC6nE7MHBwc1ppKa4p5UajgCy&u_code=d9eik113117ae1&utm_campaign=client_share&app=musically&utm_medium=ios&user_id=6763825444094854149&tt_from=copy&utm_source=copy&source=h5_m"
        }}>{`lightweight chalkboard`}</a>{`
thing (great for covid times)`}</li>
      <li parentName="ul">{`This
`}<a parentName="li" {...{
          "href": "https://www.tiktok.com/@wonderlife_aliexpress/video/6869030986298985733?language=en&sec_uid=MS4wLjABAAAAx5EuSNfZComEX7bxksQlHZHRpZPwlR02T46JDbvTC6nE7MHBwc1ppKa4p5UajgCy&u_code=d9eik113117ae1&utm_campaign=client_share&app=musically&utm_medium=ios&user_id=6763825444094854149&tt_from=copy&utm_source=copy&source=h5_m"
        }}>{`iPhone mouse thing`}</a>{`
(maybe for teens who want to destroy their friends at fortnite)`}</li>
      <li parentName="ul">{`This
`}<a parentName="li" {...{
          "href": "https://www.tiktok.com/@wonderlife_aliexpress/video/6830453905395191045?language=en&sec_uid=MS4wLjABAAAAx5EuSNfZComEX7bxksQlHZHRpZPwlR02T46JDbvTC6nE7MHBwc1ppKa4p5UajgCy&u_code=d9eik113117ae1&utm_campaign=client_share&app=musically&utm_medium=ios&user_id=6763825444094854149&tt_from=copy&utm_source=copy&source=h5_m"
        }}>{`crazy space saving foldout couch/stool thing`}</a>{`
(again, great for covid times. And looks like a big thing that could ship in a
small package). This tiktok vid has 10M views - that tells me that this
content stands out and would perform well as an ad.`}</li>
    </ul>
    <p>{`Pausing here before I spend 9 hours down the tiktok/aliexpress rabbit hole. I
got about 15 ideas on the table, and the wheels in my head are turning. I don't
want to decide today, I want to let that marinate overnight in my head.`}</p>
    <p><strong parentName="p">{`Tomorrow I have a few more tactics & methods to try for finding winning ecom
products - then I’ll start to narrow things down & kickoff the quotes/sourcing
process.`}</strong></p>
    <p>{`See ya tomorrow,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'https://www.tiktok.com/@wonderlife_aliexpress',
        label: 'Wonderlife_Aliexpress TikTok account'
      }, {
        url: 'https://www.tiktok.com/@wonderlife_aliexpress/video/6870777770771893506?language=en&sec_uid=MS4wLjABAAAAx5EuSNfZComEX7bxksQlHZHRpZPwlR02T46JDbvTC6nE7MHBwc1ppKa4p5UajgCy&u_code=d9eik113117ae1&utm_campaign=client_share&app=musically&utm_medium=ios&user_id=6763825444094854149&tt_from=copy&utm_source=copy&source=h5_m',
        label: 'Lightweight chalkboard'
      }, {
        url: 'https://www.tiktok.com/@wonderlife_aliexpress/video/6869030986298985733?language=en&sec_uid=MS4wLjABAAAAx5EuSNfZComEX7bxksQlHZHRpZPwlR02T46JDbvTC6nE7MHBwc1ppKa4p5UajgCy&u_code=d9eik113117ae1&utm_campaign=client_share&app=musically&utm_medium=ios&user_id=6763825444094854149&tt_from=copy&utm_source=copy&source=h5_m',
        label: 'iPhone mouse'
      }, {
        url: 'https://www.tiktok.com/@wonderlife_aliexpress/video/6830453905395191045?language=en&sec_uid=MS4wLjABAAAAx5EuSNfZComEX7bxksQlHZHRpZPwlR02T46JDbvTC6nE7MHBwc1ppKa4p5UajgCy&u_code=d9eik113117ae1&utm_campaign=client_share&app=musically&utm_medium=ios&user_id=6763825444094854149&tt_from=copy&utm_source=copy&source=h5_m',
        label: 'Space-saving foldout couch/stool'
      }]
    }]} mdxType="Links" />
    <PostFooter nextButton={{
      text: postMap.impulse_vs_repeat_vs_splurge_products.label,
      slug: slugify(postMap.impulse_vs_repeat_vs_splurge_products.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      